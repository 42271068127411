import React from 'react';
import './home.css'

const Home = (props: { name: string }) => {
    return (
        <div>
        <h1 className="home-title" >{props.name ? props.name + ' Logged in': 'Login to do more' }</h1>
        </div>
    );
}

export default Home;