import React from "react";
import './spotifyLoginButton.css'
// import {useEffect, useState} from 'react';
// import { loginUrl } from 'spotify-web-api-js'

// just returns a button
const SpotifyLogin = () => {

    const CLIENT_ID = ""
    const REDIRECT_URI = "http://localhost:3000"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
    const RESPONSE_TYPE = "token"

    const loginUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`
    
    // // eslint-disable-next-line
    // const [token, setToken] = useState("") 


    // useEffect(() => {
    //     const hash = window.location.hash
    //     let token = window.localStorage.getItem("spotify-token")

    //     if (!token && hash) {
    //         token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

    //         window.location.hash = ""
    //         window.localStorage.setItem("spotify-token", token)
    //     }

    //     setToken(token)

    // }, [])

   

    return (
        
        <a href={loginUrl} className="signInButton" id="signInButton">Sign In with Spotify</a>
    );
}
 
export default SpotifyLogin;


// const logout = () => {
//     setToken("")
//     window.localStorage.removeItem("token")
// }





