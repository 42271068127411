import React from 'react';
import {Link} from "react-router-dom";
import './nav.css'

const Nav = (props: { name: string, setName: (name: string) => void }) => {
    const logout = async () => {
        await fetch('http://localhost:8000/logout', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        });

        props.setName('');
    }

    let menu;

    if (props.name === '') {
        menu = (
            <>
                <li className="end">
                    <Link to="/login" className="nav-link">Login</Link>
                </li>
                <li className="end">
                    <Link to="/register" className="nav-link">Register</Link>
                </li>
            </>
        )
    } else {
        menu = (
                <li className="end">
                    <Link to="/login" className="nav-link" onClick={logout}>Logout</Link>
                </li>
        )
    }

    return (
        <nav >
            <ul className="">
                <li><Link to="/" className="header-text">Guessify (?)</Link></li>
                    {menu}
            </ul>
        </nav>
    );
};

export default Nav;