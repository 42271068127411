import React, { SyntheticEvent, useState } from 'react';
import SpotifyLoginButton from "../../components/SpotifyLoginButton/spotifyLoginButton";
import { useNavigate } from "react-router-dom";
import './register.css'
import axios from 'axios'


const Register = () => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState('');

    //TODO: add errror text
    const [errorText, setErrorText] = useState('DONT FORGET THIS');

    
    // const emailRegex = new RegExp(`^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9]+.+[a-zA-Z]$`);
    const emailRegex = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);

    const navigate = useNavigate();
    const registerEngPoint = async (e: SyntheticEvent) => {
        setErrorText('');
        e.preventDefault();
        return axios.post('http://localhost:8000/register', {
            headers: {'Content-Type': 'application/json'},
            username: username,
            password: password,
            email: email
        }).then((response) => {
            if (response.data === "OK") {
                navigate("/login")
            }
        }, (error) => {
            setErrorText(error.response.data);
        });
    }


    const checkEmail = () => {
        setValidEmail(emailRegex.test(email))
    }
    const disableSubmitButton = (username.length < 8) || (password.length < 8) || (!validEmail);


    return (
        <div id="registerBackground" className="registerBackground">

            <div className="register-box flex">
                <h1 className="register-title">Sign up to Guessify</h1>

                <div className="sign-in-text">

                    <form onSubmit={registerEngPoint}>
                        <label htmlFor="username-input">
                            <span>Username:</span>
                        </label>
                        <input type="text" className="login-text-input" id="username-input" required onChange={e => setUsername(e.target.value)}></input>

                        <label htmlFor="password-input">
                            <span>Email:</span>
                        </label>
                        <input type="text" className="login-text-input" id="email-input" required onKeyUp={checkEmail} onChange={e => setEmail(e.target.value)}></input>

                        <label htmlFor="password-input">
                            <span>Password:</span>
                        </label>
                        <input type="text" className="login-text-input" id="password-input" required onChange={e => setPassword(e.target.value)}></input>
                        <div className='danger' style={{textAlign: "center"}}>{errorText}</div>
                        
                        <div className="login-buttons">
                            <button disabled={disableSubmitButton} type="submit" className="login-button" style={{ marginTop: "5px" }}>Submit</button>
                            <span className="spotify-button-box" style={{ marginTop: "5px" }}>- or -</span>
                            <button className="login-button" style={{ marginTop: "10px" }} onClick={() => navigate("/login")}>Sign In</button>
                        </div>
                    </form>
                </div>

                <hr className="hr-seperator"></hr>

                <div className="spotify-button-box">
                    <SpotifyLoginButton />
                </div>
                {/* <div  style={{position: "absolute", bottom: 0, right: "50%"}}>*Not affiliated with Spotify</div> */}
            </div>
        </div>

    );
}

export default Register;

