import './App.css';
import React, { useEffect, useState } from 'react';

import Home from './pages/Home/home'
import Login from './pages/Login/login'
import NoPage from './pages/NoPage/noPage';
import Nav from './components/Header/nav';
import Register from './pages/Register/register';


import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  const [name, setName] = useState('');
  useEffect(() => {
    (
        async () => {
            const response = await fetch('http://localhost:8000/user', {
                headers: {'Content-Type': 'application/json'},
                credentials: 'include'
            });

            const content = await response.json();

            setName(content.username);
        }
    )();
});



  return (
    <div className="background">
      <BrowserRouter>
        <Nav name={name} setName={setName} />
        <main className="form-signin">
          <Routes>
            <Route path="/" Component={() => <Home name={name} />} />
            <Route path="/login" Component={() => <Login setName={setName} />} />
            <Route path="/register" Component={() => <Register />} />
            <Route path="*" Component={() => <NoPage />} />
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;

