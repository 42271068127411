import React, { SyntheticEvent, useState } from 'react';
import './login.css'
import SpotifyLoginButton from "../../components/SpotifyLoginButton/spotifyLoginButton";
import { useNavigate } from "react-router-dom";
// import submitLogin from "./loginAPI"
import axios from 'axios'

const Login = (props: { setName: (name: string) => void }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');


    const navigate = useNavigate();
    const loginEndpoint = async (e: SyntheticEvent) => {
        setErrorText('');
        e.preventDefault();
        await axios.post('http://localhost:8000/login', {
            headers: {'Content-Type': 'application/json'},
            username: username,
            password: password
        }, {withCredentials: true}).then((response) => {
            if (response.data !== "") {
                props.setName(response.data);
                navigate("/");
            }
        }, (error) => {
            setErrorText(error.response.data);
        });

    }

    const disableSignInButton = (username.length < 8) || (password.length === 0);

    return (
        <div id="loginBackground" className="loginBackground">

            <div className="login-box flex">
                <h1 className="login-title">Log in to Guessify</h1>

                <div className="sign-in-text">

                    <form onSubmit={loginEndpoint}>
                        <label htmlFor="username-input">
                            <span>Username or Email:</span>
                        </label>
                        <input type="text" className="login-text-input" id="username-input" style={{ marginBottom: "15px" }} onChange={e => setUsername(e.target.value)} required></input>

                        <label htmlFor="password-input" >
                            <span>Password:</span>
                        </label>
                        <input type="password" className="login-text-input" id="password-input" onChange={e => setPassword(e.target.value)} required></input>
                            <div className='danger' style={{textAlign: "center"}}>{errorText}</div>
                        <div className="login-buttons">
                            <button type="submit" disabled={disableSignInButton} className="login-button" style={{ marginTop: "5px" }} id="submit" >Sign In</button>
                            <span className="spotify-button-box" style={{ marginTop: "5px" }}>- or -</span>
                            <button type="button" className="login-button" id="createAccount" style={{ marginTop: "10px" }} onClick={() => navigate('/register')}>Create Account</button>
                        </div>
                    </form>
                </div>

                <hr className="hr-seperator"></hr>

                <div className="spotify-button-box">
                    <SpotifyLoginButton />
                </div>
                {/* <div  style={{position: "absolute", bottom: 0, right: "50%"}}>*Not affiliated with Spotify</div> */}
            </div>
        </div>

    );
}

export default Login;

